<template>
  <notifications />
  <HomePage />
</template>

<script>
import HomePage from '@/components/HomePage'

export default {
  name: 'App',
  components: {
    HomePage
  },
  mounted () {
    const vh = window.innerHeight * 0.01

    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}
</script>

<style lang="scss" src="./style.scss"></style>

<template>
  <div
    class="project"
    :class="[
      `project_${project.name.toLowerCase()}`,
      {'project_claim': page === 'claim'}
    ]"
    @click="clickHandler"
  >
    <div class="project__logo">
      <img :src="logoImg" :alt="project.name">
    </div>
    <div class="project__girl">
      <img :src="girlImg">
    </div>
    <div class="project__bonuses">
      <div class="project__bonus">
        <div v-html="textDeposit"></div>
      </div>
      <div class="project__bonus">
        <div v-html="textFreespins"></div>
      </div>
    </div>
    <div class="project__promocode" @click.stop="copyToClipboard">
      <small>{{ textPromocode }}</small>
      <span>{{ promocode }}</span>
      <img src="@/images/copy.svg" class="project__copy-button">
    </div>
  </div>
</template>

<script>
import { TEXT_DEPOSIT, TEXT_FREESPINS, PROMOCODE, TEXT_PROMOCODE } from '@/constants'

export default {
  name: 'ProjectBlock',
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    page: {
      type: String,
      default: ''
    }
  },
  created () {
    this.textDeposit = TEXT_DEPOSIT
    this.textFreespins = TEXT_FREESPINS
    this.textPromocode = TEXT_PROMOCODE
    this.promocode = PROMOCODE
  },
  computed: {
    logoImg () {
      return require(`@/images/logos/${this.project.logo}`)
    },
    girlImg () {
      return require(`@/images/girls/${this.page === 'claim' ? this.project.girl1 : this.project.girl2}`)
    }
  },
  methods: {
    copyToClipboard () {
      navigator.clipboard.writeText(this.promocode).then(() => {
        this.$notify({
          text: 'Código promocional copiado',
          type: 'success'
        })
      }, () => {
        this.$notify({
          text: 'Error copying to clipboard',
          type: 'warn'
        })
      })
    },
    clickHandler (event) {
      window.open(this.project.url)
    }
  }
}
</script>

<style lang="scss" src="./style.scss"></style>

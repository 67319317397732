export const PROJECTS = [
  {
    name: 'Izzi',
    logo: 'izzi-logo.svg',
    girl1: 'purple-dress.webp',
    girl2: 'purple-dress-2.webp',
    url: 'https://izzicasino.life/cdb2bbbef'
  },
  {
    name: 'Fresh',
    logo: 'fresh-logo.svg',
    girl1: 'green-dress.webp',
    girl2: 'green-dress-2.webp',
    url: 'https://freshcasino.life/c3bcaf074'
  },
  {
    name: 'Sol',
    logo: 'sol-logo.svg',
    girl1: 'yellow-dress.webp',
    girl2: 'yellow-dress-2.webp',
    url: 'https://solcasino.life/c676e8d6c'
  },
  {
    name: 'Starda',
    logo: 'starda-logo.svg',
    girl1: 'red-dress.webp',
    girl2: 'red-dress-2.webp',
    url: 'https://stardacasino.life/c61b95f1a'
  },
  {
    name: 'Legzo',
    logo: 'legzo-logo.svg',
    girl1: 'blue-dress.webp',
    girl2: 'blue-dress-2.webp',
    url: 'https://legzocasino.life/c0e3d702f'
  }
]

export const TEXT_DEPOSIT = '<strong>100%</strong><br>PARA O DEPÓSITO'

export const TEXT_FREESPINS = '<strong>500</strong><br>rodadas grátis'

export const TEXT_PROMOCODE = 'Сódigo'

export const PROMOCODE = 'ALINE'

export const TEXT_CLAIM_BONUS = 'Pegar o bônus'

export const TEXT_ALL_BONUSES = 'TODOS OS BÓNUS'

export const TEXT_BOTTOM = 'Para maiores de +18<br> Lembre-se;<br> Não se trata de investimento é entretenimento responsável'

export const SOCIALS = [
  {
    url: 'https://t.me/+2fPtauRMKIY0ODQx',
    socialName: 'telegram',
    userName: 'Canal VIP'
  },
  {
    url: 'https://t.me/c/1774116560/131',
    socialName: 'telegram',
    userName: '@Aline'
  },
  {
    url: 'https://www.youtube.com/@alinefariaslives/featured',
    socialName: 'youtube',
    userName: '@AlineLives'
  },
  {
    url: 'https://instagram.com/alinefarias',
    socialName: 'instagram',
    userName: '@Alinefaries'
  },
  {
    url: 'https://twitch.tv/S2alinefaria',
    socialName: 'twitch',
    userName: '@s2alinefaria'
  },
  {
    url: 'https://twitter.com/alinefariaass',
    socialName: 'twitter',
    userName: '@alinefariaass'
  }
]

<template>
  <div :class="pageClass" class="claim-bonus">
    <transition-group name="fade">
      <div
        v-for="project in projects"
        :key="project.name"
        :class="[
            `claim-bonus__background-${project.name.toLowerCase()}`,
            {'active': project.name === currentProjectName}
          ]"
        class="claim-bonus__background"
      ></div>
    </transition-group>
    <div class="claim-bonus__side-background"></div>
    <swiper
      :slidesPerView="1"
      :mousewheel="true"
      :pagination="false"
      :centeredSlides="true"
      :navigation="true"
      :modules="modules"
      :effect="'coverflow'"
      :space-between="70"
      :coverflowEffect="{
        rotate: -20,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }"
      class="bonus-swiper"
      @slideChange="slideChange"
    >
      <swiper-slide v-for="project in projects" :key="project.name">
        <ProjectBlock :project="project" page="claim" />
        <a :href="currentProjectUrl" class="claim-bonus__button">
          {{ textClaimBonus }}
        </a>
        <div class="claim-bonus__all-bonuses-link" @click="goToAllBonuses">
          {{ textAllBonuses }}
        </div>
      </swiper-slide>
      <transition-group name="fade">
        <div
          v-for="project in projects"
          :key="project.name"
          :class="[
            `claim-bonus__girl_${project.name.toLowerCase()}`,
            {'active': project.name === currentProjectName}
          ]"
          class="claim-bonus__girl"
        >
          <img :src="getGirlImg(project)" alt="">
        </div>
      </transition-group>
    </swiper>

  </div>
</template>

<script>
import './style.scss'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, EffectCoverflow, Mousewheel } from 'swiper/modules'
import { PROJECTS, TEXT_CLAIM_BONUS, TEXT_ALL_BONUSES } from '@/constants'
import ProjectBlock from '@/components/ProjectBlock/index.vue'

export default {
  data () {
    return {
      activeSlideIndex: 0
    }
  },
  components: {
    ProjectBlock,
    Swiper,
    SwiperSlide
  },
  created () {
    this.projects = PROJECTS
    this.textClaimBonus = TEXT_CLAIM_BONUS
    this.textAllBonuses = TEXT_ALL_BONUSES
  },
  computed: {
    currentProjectName () {
      return PROJECTS[this.activeSlideIndex].name
    },
    currentProjectUrl () {
      return PROJECTS[this.activeSlideIndex].url
    },
    pageClass () {
      return `claim-bonus_${this.currentProjectName.toLowerCase()}`
    }
  },
  methods: {
    slideChange (swiper) {
      this.activeSlideIndex = swiper.realIndex
    },
    getGirlImg (project) {
      return require(`@/images/girls/${project.girl1}`)
    },
    goToAllBonuses () {
      this.$emit('togglePage')
    }
  },
  setup () {
    return {
      modules: [Navigation, EffectCoverflow, Mousewheel]
    }
  }
}
</script>

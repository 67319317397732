<template>
  <div class="all-bonuses">
    <div class="all-bonuses__title">{{ textAllBonuses }}</div>
    <div class="all-bonuses__projects">
      <div class="all-bonuses__project" v-for="project in projects" :key="project.name" >
        <ProjectBlock :project="project" />
      </div>
    </div>
    <div class="all-binuses__bottom-text" v-html="textBottom"></div>
  </div>
</template>

<script>
import './style.scss'
import ProjectBlock from '@/components/ProjectBlock'
import { PROJECTS, TEXT_ALL_BONUSES, TEXT_BOTTOM } from '@/constants'

export default {
  name: 'AllBonuses',
  components: {
    ProjectBlock
  },
  created () {
    this.projects = PROJECTS
    this.textAllBonuses = TEXT_ALL_BONUSES
    this.textBottom = TEXT_BOTTOM
  }
}
</script>

<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isNavOpen"></div>
    <transition name="slide">
      <div v-show="isNavOpen"
           class="sidebar-panel scrollbar">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SideBarComponent',
  props: {
    isNavOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeSidebarPanel () {
      console.log('toggle')
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/mixins.scss';

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.sidebar {
  position: absolute;
  width: 0;
  height: 100%;
  z-index: 15;
  left: 0;
  top: 0;
}

.sidebar-backdrop {
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(5.5px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #130f40;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 999;
  box-sizing: border-box;
  background-image: url('../../images/back.webp');
  background-size: cover;

  @include portrait {
    width: 100%;
    box-sizing: border-box;
    padding: 24.3vw 4.3vw 0 4.3vw;
  }

  @include landscape {
    width: 25.5vw;
    padding: 9.3vw 3.3vw 0 3.3vw;
  }
}
</style>

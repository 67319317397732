<template>
  <div id="burger" :class="{ 'active' : isNavOpen }" @click.prevent="toggleMenu">
    <slot>
      <button type="button" class="burger-button" title="Menu"></button>
    </slot>
  </div>
</template>
<script>

export default {
  name: 'BurgerComponent',
  props: {
    isNavOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/mixins.scss';

.hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.burger-button {
  position: absolute;
  display: block;
  z-index: 999;
  border: 0;
  pointer-events: all;
  transition: transform 0.6s;
  border-radius: 50%;
  background-image: url('../../images/burger-back.webp');
  backdrop-filter: blur(8.1px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-image: url('../../images/burger-back-hover.webp');
    background-size: 90%;
  }

  @include portrait {
    left: 4vw;
    top: 4vw;
    width: 13vw;
    height: 13vw;
  }

  @include landscape {
    left: 1.3vw;
    top: 1.3vw;
    height: 4.2vw;
    width: 4.2vw;
    padding: 1.25vw;
  }
}

#burger {
  &.active {
    .burger-button {
      background-image: url('../../images/burger-back-active.webp');

      &:hover {
        background-image: url('../../images/burger-back-active-hover.webp');
        background-size: 90%;
      }
    }
  }
}
</style>

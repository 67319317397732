<template>
  <transition name="slide-down">
    <div
      v-if="!allBonusesPage"
      :class="{'isHidden': allBonusesPage}"
      class="claim-bonus-slide"
    >
      <ClaimBonus @togglePage="togglePage" />
    </div>
  </transition>
  <transition name="slide-fade">
    <div
      v-if="allBonusesPage || !isLandscape"
      class="all-bonuses-slide"
      ref="bonuses"
    >
      <AllBonuses />
    </div>
  </transition>
  <nav class="burger-button-component">
    <BurgerComponent @toggleMenu="toggleMenu" :is-nav-open="isNavOpen"></BurgerComponent>
  </nav>
  <SideBarComponent @toggleMenu="toggleMenu" :is-nav-open="isNavOpen">
    <ul class="sidebar-panel-nav">
      <li
        v-for="social in socials"
        :key="`${social.socialName}${social.userName}`"
        class="sidebar-panel-nav__item"
      >
        <a target="_blank" :href="social.url" class="sidebar__link">
          <span class="sidebar__link-title">{{ social.socialName }}</span>
          <span>{{ social.userName }}</span>
          <img :src="getSocialImg(social.socialName)" class="sidebar__link-icon">
        </a>
      </li>
    </ul>
  </SideBarComponent>
</template>

<script>
import AllBonuses from '@/components/AllBonuses'
import ClaimBonus from '@/components/ClaimBonus'
import BurgerComponent from '@/components/Menu/BurgerComponent.vue'
import SideBarComponent from '@/components/Menu/SideBarComponent.vue'
import { SOCIALS } from '@/constants'

import 'swiper/css'
import 'swiper/css/pagination'

export default {
  name: 'HomePage',
  data () {
    return {
      allBonusesPage: false,
      isNavOpen: false,
      isLandscape: false,
      scrollCounter: 0
    }
  },
  components: {
    AllBonuses,
    ClaimBonus,
    BurgerComponent,
    SideBarComponent
  },
  created () {
    this.socials = SOCIALS
    this.isLandscape = window.matchMedia('(orientation: landscape)').matches

    window.addEventListener('resize', () => {
      this.isLandscape = window.matchMedia('(orientation: landscape)').matches
    })

    window.addEventListener('wheel', this.scrollHandler)
  },
  methods: {
    togglePage () {
      this.allBonusesPage = !this.allBonusesPage
    },
    toggleMenu () {
      this.isNavOpen = !this.isNavOpen
    },
    getSocialImg (socialName) {
      return require(`@/images/socials/${socialName}.webp`)
    },
    scrollHandler () {
      if (!this.allBonusesPage) { return }

      if (window.scrollY <= 0) {
        this.scrollCounter++
      }

      if (window.scrollY <= 0 && this.scrollCounter > 3) {
        this.scrollCounter = -1
        this.allBonusesPage = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/mixins.scss';

.claim-bonus-slide {
  width: 100%;
  overflow: hidden;
  margin-top: 0;

  @include portrait {
    position: relative;
  }

  @include landscape {
    min-height: 100vh;
  }

  &.isHidden {
    min-height: 0;
    height: 0;
  }
}

.all-bonuses-slide {
  @include landscape {
    padding-top: 6vh;
  }
}

.burger-button-component {
  position: fixed;
  left: 1vw;
  top: 1vw;
  z-index: 16;
}

.sidebar__link {
  position: relative;
  border-radius: 20px;
  border: 2px solid rgba(171, 74, 248, 0.30);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(76.16% 34.85% at 54.03% 100.00%, rgba(171, 74, 248, 0.40) 0%, rgba(124, 70, 193, 0.00) 100%), linear-gradient(360deg, rgba(9, 9, 9, 0.23) 0%, rgba(9, 9, 9, 0.00) 100%);
  backdrop-filter: blur(13.5px);
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.4;
  transition: background .3s;

  &:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(166.05% 75.98% at 54.03% 100.00%, rgba(171, 74, 248, 0.70) 0%, rgba(124, 70, 193, 0.00) 100%), linear-gradient(360deg, rgba(9, 9, 9, 0.23) 0%, rgba(9, 9, 9, 0.00) 100%);
  }

  @include portrait {
    font-size: 4.8vw;
    padding: 4.1vw 0;
  }

  @include landscape {
    font-size: 1.25vw;
    padding: 1.06vw 0;
  }
}

.sidebar__link-title {
  opacity: .8;

  @include portrait {
    font-size: 2.7vw;
  }

  @include landscape {
    font-size: .85vw;
  }
}

.sidebar__link-icon {
  position: absolute;
  right: -8%;
  top: 24%;
  width: 27%;

  @include landscape {
    top: 38%;
  }
}

.sidebar-panel-nav__item {
  overflow: hidden;

  @include portrait {
    margin-bottom: 4.3vw;
  }

  @include landscape {
    margin-bottom: 1vw;
  }
}

.slide-down-enter-active {
  transition: all 0.2s ease-out;
}

.slide-down-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-100vh);
  opacity: 0;
}
</style>
